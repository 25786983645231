import React, {Component} from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class MerchPage extends Component {

    state = {
        stripe: null,
        buttonState: false,
        // The cart should be initialized from a graphQL query.
        cart: this.props.data.skus.edges.map(item => ({sku: item.node.id, quantity: 0}))
      }
    
      componentDidMount() {
        const stripe = window.Stripe("pk_live_CvrRyJ6hc5KSEQ4bCV2Q8fYR00PP9S8nep");
        this.setState({ stripe });
        //console.log(this.state.cart);
      }
    
      handleIncrement(event, sku) {
        let cart = this.state.cart;
        cart.find((item) => item.sku === sku).quantity++;
        //console.log(cart.find((item) => item.sku === sku).quantity)
        this.setState(cart);
      }

      handleDecrement(event, sku) {
        let cart = this.state.cart;
        if(cart.find((item) => item.sku === sku).quantity > 0)
        {
            cart.find((item) => item.sku === sku).quantity--;
        }
        //console.log(cart.find((item) => item.sku === sku).quantity);
        this.setState(cart);
      }
    
      async redirectToCheckout(event, cart) {
          const finalCart = cart.filter((item) => item.quantity > 0)
          event.preventDefault()
          console.log(finalCart);
          if(finalCart.length > 0)
          {
            const { error } = await this.state.stripe.redirectToCheckout({
              items: finalCart,
              successUrl: `http://dropdiver.com/payment-successful/`,
              cancelUrl: `http://dropdiver.com/merch`,
              billingAddressCollection: 'required'
            })
        
            if (error) {
              console.warn("Error:", error)
            }
        }
      }

  render() {
    return(
        <Layout>
        <div>
          <SEO title="Merch" />
          <h1 className="title">MERCH</h1>
          <hr className="divider rounded"/>
          <h2 className="subtitle">BUY OUR SHI<span className="flicker">R</span>T <i className="fas fa-tshirt"></i></h2>
          <hr className="divider rounded"/>
          <div className="merch-container">
        <StaticQuery
            query={graphql`
            query AllSkus {
                skus: allStripeSku {
                edges {
                    node {
                    id
                    active
                    price
                    currency
                    image
                    attributes {
                      name
                    }
                    product{
                      id
                    }
                    }
                }
                }
            }
            `}
            render={({ skus }) => (
                skus.edges.map(({ node: sku }) => (
                    <div className="merch-item"  key={sku.id}>
                    <div className="merch-item-desc">
                        <img className="merch-image" alt={sku.name} src={sku.image}></img>
                        <h4>{sku.attributes.name} {sku.size} ${(sku.price/100).toFixed(2)}</h4>
                        <div className="merch-quantity-container">
                        <button className="merch-quantity-button" onClick={event => this.handleDecrement(event, sku.id)}><i className="fas fa-lg fa-minus"></i></button>
                        <span>{this.state.cart.find((item) => item.sku === sku.id).quantity}</span>
                        <i className="fas fa-2x fa-shopping-cart"></i>
                        <button className="merch-quantity-button" onClick={event => this.handleIncrement(event, sku.id)}><i className="fas fa-lg fa-plus"></i></button>
                        </div>
                    </div>
                    </div>
                    ))
                )}
            />
        <button onClick={event => this.redirectToCheckout(event, this.state.cart)} className="button checkout-button">PURCHASE <i className="fas fa-shopping-cart"></i></button>
        </div>
      </div>
    </Layout>
    )
    }
}

export default MerchPage